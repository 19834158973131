import React, { useEffect } from "react";
import { DISCORD_INVITE_URL } from "../constants";
import styled from "styled-components";

const Page = styled.div`
  min-height: 100vh;
  background: #000212;
  color: white;
`;

const DiscordRedirectPage = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof window !== undefined) {
      window.location.replace(DISCORD_INVITE_URL);
    }
  }, []);

  return <Page />;
};

export default DiscordRedirectPage;
